html {
  scroll-behavior: smooth;
}
body{
  font-size: 14px;
  position: relative; 
  min-height:100vh; 
}
a:hover{
  text-decoration: none;
}
.t-color{
  color: #E54C2A;
}
.f-12{
  font-size: 12px;
}
section{
  margin: 2.5rem 0;
}
.hide{
  display: none;
}
.show{
  display: show;
}
.card-box {
    background: #fff;
    box-shadow: 0px 0px 20px 0px #e2e2e2b8;
    position: relative;
    border-radius: 4px;
    margin-bottom: 30px;
}
.button,
.button:hover{
  text-align: center;
    color: #fff;
    margin-top: 22px;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 0;
    line-height: 12px;
    background: #e54c2a;
    text-decoration: none;
}
.button-sm,
.button-sm:hover{
  text-align: center;
    color: #fff;
    margin-top: 22px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    line-height: 12px;
    background: #e54c2a;
    border:1px solid #e54c2a;
    text-decoration: none;
}
.button-sm-outline,
.button-sm-outline:hover{
  text-align: center;
    color: #fff;
    margin-top: 22px;
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    line-height: 12px;
    border:1px solid #e54c2a;
    text-decoration: none;
}
.navbar-toggler .simple-icon-menu{
  color: #fff;
    font-size: 30px;
}
.header-wrap{
  content: '';
  /*background-image: url(../images/food-banner.jpg);*/
  width: 100%;
  /*height: 180px;*/
  background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.header-wrap .navbar{
  /*background: rgba(0, 0, 0, 0.60);*/
  background-color: #343a40;
  position: relative;
    z-index: 9;
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: #fff;
  text-transform: uppercase;
  padding: .5rem .75rem;
  margin-left: 15px;
}
/*.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-expand-lg .navbar-nav .nav-link:hover{
  color: #E54C2A;
}*/

.order-opt{
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0 0;
  position: relative;
  text-align: center;
}
.order-opt .delivery-opt,
.order-opt .pick-up-opt{
  margin-bottom: 20px;
  cursor: pointer;
}
.order-opt .delivery-opt a,
.order-opt .pick-up-opt a{
  border: 1px solid #ccc;
    height: 150px;
    width: 150px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    padding-top: 25px;
}
.order-opt .delivery-opt a.active,
.order-opt .pick-up-opt a.active{
  border: 1px solid #E54C2A;
}
.order-opt .delivery-opt:hover a,
.order-opt .pick-up-opt:hover a{
  -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}
.delivery-opt-form label{
  margin-bottom: .2rem;
}
.delivery-opt-form .form-control,
.delivery-opt-form .form-control:focus,
.pick-up-addr .form-control,
.pick-up-addr .form-control:focus{
  border-radius: 0;
  box-shadow: none;
  
}
.delivery-opt-form .form-control:focus{
  border-color: #fda591;
}
.pick-up-addr i{
  color: #E54C2A;
  font-weight: 700;
}
.item-filter{
  border-bottom: 1px solid #e5e5e5;
  margin: 0 15px;
  padding: 0 0 20px 0;
}
/*.item-filter .search-by-filter{
  border: 1px solid #e5e5e5;
    border-radius: 4px;
    background: #fff;
    margin-bottom: 15px;
}*/
.item-filter .search-by-filter h4{
  text-align: center;
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 26px 0 24px;
    line-height: 12px;
    background: #e54c2a;
    text-transform: uppercase;
}

.item-filter .search-by-filter .filter{
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
}
.item-filter .search-by-filter .filter .filter-child{
  width:25%;
}
.item-filter .search-by-filter .filter h3{
  font-size: 16px;
    font-weight: 600;
    color: #e54c2a;
    margin-left: 15px;
    margin-top: 15px;
    text-transform: uppercase;
}
.item-filter .search-by-filter .filter ul{
  list-style: none;
  padding-left: 0;
  /*display: flex;
  align-items: baseline;
  margin-top: 1rem;*/
}
.item-filter .search-by-filter .filter ul li{
  padding: 0px 15px;
}
.item-filter .search-by-filter .filter ul li .form-group,
.form-group{
  margin-bottom: .25rem;
  margin-top: 5px;
}
.item-filter .search-by-filter .filter ul li .form-group input,
.form-group input[type=checkbox] {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.item-filter .search-by-filter .filter ul li .form-group label ,
.form-group label.check{
  position: relative;
  cursor: pointer;
  display: flex;
}
.item-filter .search-by-filter .filter ul li .form-group label:before,
.form-group label.check:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #e54c2a;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.item-filter .search-by-filter .filter ul li .form-group input:checked + label:after,
.form-group input:checked + label.check:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid #e54c2a;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.items{
  margin-bottom: 30px;
  display: flex;
  width: 49%;
}
.items .item-img{
    border-radius: 4px 0px 0 4px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    background: #fff;
    position: relative;
    display: flex;
    align-items: center;
}
.items .item-img img{
  width: 100px;
}
.items .item-detail{
  text-align: left;
    border: 1px solid #e5e5e5;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    width:70%;
    padding: 5px;
    position: relative;
    float: left;
}
.items .item-detail p{
  margin-bottom: 0
}
.items .item-detail h4 a{
  font-size: 18px;
  color: #e54c2a;
  width: 80%;
  display: block;
}
.items .item-detail .amount{
  font-size: 14px;
  font-weight: 600;
  position: absolute;
    right: 5px;
    top: 5px;
}
.item-cart{
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding-bottom: 15px;
  margin-top: 15px;
}
.item-cart h4,
.order-addr h4{
  text-align: center;
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    padding: 26px 0 24px;
    line-height: 12px;
    background: #e54c2a;
    text-transform: uppercase;
}
.item-cart ul{
  list-style: none;
  padding-left: 0;
  padding: 15px;
  margin-bottom: 0;
}
.item-cart ul li .cart-order{
  display: flex;
  /*border-bottom: 1px solid #e5e5e5;*/
  padding: 10px 0;
}
.item-cart ul li .cart-order .qua{
  font-size: 18px;
    font-weight: 600;
    color: #E44C2A;
    width: 35%;
}
.item-cart ul li .cart-order .order-name{
  font-size: 16px;
  width: 55%;
  font-weight: 500;
  word-break: break-all;
  padding-right: 10px;
}
.item-cart ul li .cart-order .order-name .amount{
  font-size: 14px;
  font-weight: 400;
}
.item-cart ul li .cart-order .action{
  width: 10%;
  text-align: right;
  
}
.item-cart ul li .cart-order .action a{
  font-size: 24px;
    color: #E44C2A;
}
.item-cart ul li .cart-order .cart-qty .btn{
  padding: .375rem 0;
}
.item-cart ul li .cart-order .cart-qty input{
  margin: 0 5px;
    padding: 1px;
    height: 35px;
}
.item-cart ul li .cart-order .cart-qty input:focus{
  box-shadow: none;
}
.item-cart .total{
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}
/*.item-cart .final-order{
  display: flex;
    justify-content: space-between;
}*/
#itemDetail .modal-head{
  /* background-image: url(../images/banner-img.jpeg); */
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
}
#itemDetail .modal-body h2{
  color: #E44C2A;
  font-size: 24px;
  margin-bottom: 0px;
}
#itemDetail .modal-body .setect-qt{
  margin-top: 20px;
}
.btn:focus {
  box-shadow: none;
}
.driver-tip{
  display: flex;
}
.driver-tip .form-check-inline{
  margin-right: 0;
}
.driver-tip .form-check .form-check-label{
  position: relative;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 5px;
    
}
.driver-tip .form-check-inline input:checked + label{
  border:1px solid #e54c2a;
}
.driver-tip .form-check-inline input:checked + label span {
  position: relative;
    z-index: 9;
    color: #fff;
}

.driver-tip .form-check-inline input:checked + label:after {
  content: '';
  width: 38px;
  height: 32px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #e54c2a;
  text-shadow: none;
  font-size: 32px;
  border: 0;
}
.driver-tip .form-check .form-check-input{
  opacity: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.driver-tip .form-check .custom-tip,
.driver-tip .form-check .custom-tip:focus{
  height: 33px;
    width: 100%;
    padding: 0 4px;
    box-shadow: none;
    outline: 0;
    border: 1px solid #ccc;
    text-align: right;
}
.popularity{
  position: absolute;
    z-index: 9;
    top: 10px;
    left: 10px;
    right: 10px;
    margin: auto;
    text-align: center;
    background: #ED7D31;
    padding: 3px;
    font-size: 12px;
    font-weight: 500;
}
.most-popular{
    background: #ED7D31;
}
.discount{
    background: #fbfc1b;
}
.holiday{
    background: #90de25;
}
.redeem-point{
  background: #FFF9D9;
    text-align: center;
    border: 1px solid #e54c2a;
}
.mobileCart,
.mobileCart:focus{
  display: none;
  color: #fff
}
.category-hz{
  background: #FAFAFA;
  box-shadow: 0 0 4px #e1e1e1;
  border-bottom: 1px solid #e5e5e5;
}
.category-btn{
  display: flex;
  float: right;
}
.category-hz a{
  border-left: 1px solid #e5e5e5;
    border-radius: 0;
    padding: 10px 25px;
    color: #000;
}
.category-hz a:last-child{
  border-right: 1px solid #e5e5e5;
}

.filter-hz{
  margin: 30px  0 0 !important;
}
.filter-hz .nav-tabs{
  border-bottom: 0;
}
.filter-hz .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  border-radius: .25rem;
    margin-right: 10px;
    background-color: #fff;
    color: #000;
    padding: 10px 30px;
    border-color: #e9ecef #e9ecef #dee2e6;
}
.filter-hz .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background-color:#E54C2A;
  color: #fff;
  border-color: #E54C2A;
}
.filter-item-result{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
footer{
  position: absolute;
    width: 100%;
    bottom: -50px;

}
.navbar-brand i{
  font-size: 16px;
    margin-right: 10px;
}
.order-addr{
  border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-top: 35px;
    
}
.order-addr label{
  margin-bottom: .2rem;
}
input[type=text],
input[type=text]:focus{
  border-radius: 0;
  box-shadow: none;
}
.input-group-prepend .input-group-text{
  background-color: #fff;
  font-size: 14px;
  padding: 10px;
}

/*LOGIN*/

.login-wrap{
  margin-top: 70px;
}
.login-wrap .card{
  border-radius: 0;
}
.login-wrap .card-head{
  background-color: #343a40;
  padding: 15px 10px;
  text-align: center;
}
.chechout-head{
  padding: 10px;
  background: #FFF9D9;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
}
button{
  border: 0;
}

@media(max-width: 800px){
  /*.item-filter{
    display: flex;
    justify-content: space-between;
  }*/
  /*.item-filter .search-by-filter{
    width: 24%;
  }
  .item-filter .search-by-filter .filter{
    border: 0;
  }
  .item-filter .search-by-filter .filter ul li {
    padding: 0px 5px;
    font-size: 12px;
  }*/
  .mobileCart{
    color: #fff;
    font-size: 30px;
    display: block;
    position: absolute;
    right: 7rem;
    top: 10px;
    padding: 0 10px;
  }
  .item-cart, .order-addr{
    margin-top: 15px;
  }
}

@media(max-width: 580px){
  .order-opt{
    display: block;
  }
  .order-opt .delivery-opt,
  .order-opt .pick-up-opt{
    margin: 20px 0;
  }
  .items{
    display: block;
    margin-top: 25px;
  }
  .items .item-img{
      border-radius: 4px 4px 0 0px;
      width:100%;
      border-bottom: 0;
      text-align: center;
  }
  .items .item-detail{
      border-left:1px solid #e5e5e5;
      width:100%;
  }
  .item-filter{
    display: block;
  }
  .item-filter .search-by-filter {
    width: 100%;
  }
  .item-filter .search-by-filter .filter ul li {
    padding: 0px 15px;
    font-size: 16px;
  }
  .mobileCart{
    color: #fff;
    font-size: 30px;
    display: block;
    position: absolute;
    right: 5rem;
    padding: 0 10px;
  }
  .category-hz a{
    padding: 10px;
  }
  .items .item-detail h4 a{
    font-size: 20px;
  }
  .items .item-detail .amount{
    font-size: 16px;
  }
  .item-filter .search-by-filter .filter .filter-child{
    width: 50%;
  }
  .item-cart, .order-addr{
    margin-top: 15px;
  }
}